<template>
  <div class="contactUs">
    <img src="~@/assets/img/contact_us@2x.jpg" class="head_img"/>
    <div class="head_title">企业愿景</div>
    <div class="striping head_striping"></div>
    <div style="width: 65%;margin: 0 auto">
      <div style="font-size: 16px;font-weight: 400;color: #4C4C4C;line-height: 32px;margin-bottom: 100px;">
        麦菲斯影响力不断提高，企业文化独具特色，有核心的竞争优势和创新能力，为社会创造价值，能够健康、持续稳定发展，成为令社会尊重的全国性的综合运营商。
        在“规模与效益并举，产品与服务共进”的战略指引下，公司发展全面提速，通过创新业务拓展，围绕新型“地产+互联网+医疗”的服务运营平台，合纵连横整合产业链相关资源，增加服务多样性，扩大市场规模，提高市场增值，多元化发展，服务企业，合作共赢，永无止境。
      </div>
      <img src="~@/assets/img/contact_01@2x.png" class="bottom_img"/></div>
    <div class="footer">
      <div>
        <div>
          <img src="~@/assets/img/email@2x.png"/>
          <div>公司邮箱</div>
          <div>15502948812@139.com</div>
        </div>
        <div class="line"></div>
        <div>
          <img src="~@/assets/img/phone@2x.png"/>
          <div>销售咨询</div>
          <div>029-88605821</div>
        </div>
        <div class="line"></div>
        <div>
          <img src="~@/assets/img/support@2x.png"/>
          <div>技术支持</div>
          <div>029-88605821</div>
        </div>
        <div class="line"></div>
        <div>
          <img src="~@/assets/img/location@2x.png"/>
          <div>公司地址</div>
          <div>丈八一路10号中铁西安中心</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactUs"
}
</script>

<style scoped lang="scss">
.bottom_img {
  width: 100%;
  height: 300px;
  margin-bottom: 130px;
}

.footer {
  height: 378px;
  background: url("~@/assets/img/contact_infobg@2x.png") no-repeat 100%;
  //background-size: 100%;

  .line {
    width: 1px;
    height: 150px;
    background: #FFFFFF;
    opacity: 0.2;
    flex: 0.001;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 98px 200px 115px 200px;

    & > div {
      flex: .24;
      text-align: center;

      img {
        width: 70px;
        height: 70px;
        margin-bottom: 40px;
      }

      & > div {
        color: #FFFFFF;

        &:nth-child(2) {
          font-size: 22px;
          line-height: 33px;
          margin-bottom: 19px;
        }

        &:nth-child(3) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>